import Image from "next/image";

import { NAVIGATION_ROUTE } from "../../constants/navigationRoute";

import * as S from "./ErrorPage.style";

const ErrorPage = () => {
  return (
    <S.Container>
      <S.ImageWrapper>
        <Image
          src="/assets/not-found.png"
          alt="not-found"
          quality={50}
          objectFit="contain"
          width={0}
          height={0}
          sizes="100vw"
          style={{ width: "100%", height: "auto" }}
        />
      </S.ImageWrapper>
      <S.TextWrapper>
        <S.Title name="h2">That Page is gone</S.Title>
        <S.Description name="strongM">
          {
            "Looks like you discovered a page\nthat doesn't exist or you don't have access to."
          }
        </S.Description>
      </S.TextWrapper>
      <S.ReturnHomeLink href={NAVIGATION_ROUTE.EVENT_LIST.HREF}>
        Return home
      </S.ReturnHomeLink>
    </S.Container>
  );
};

export default ErrorPage;
