import Link from "next/link";

import styled from "styled-components";

import { Typo } from "@enkor/design/components";
import { grayScale, pink } from "@enkor/design/colors";
import { APP_SIZE } from "@styles/variables";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 68px;
`;

export const ImageWrapper = styled.div`
  position: relative;

  width: 100%;
  max-width: ${APP_SIZE.MAX_WIDTH}px;
  height: auto;
  margin-bottom: 20px;
`;

export const TextWrapper = styled.div`
  width: 100%;
  max-width: 320px;
  margin: 0 16px;
`;

export const Title = styled(Typo.Heading)`
  margin-bottom: 16px;

  text-align: center;
`;

export const Description = styled(Typo.Text)`
  margin-bottom: 42px;

  text-align: center;
  white-space: pre-line;
`;

export const ReturnHomeLink = styled(Link)`
  padding: 9px 16px;
  border-radius: 8px;

  background: ${pink.main};

  color: ${grayScale.white};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;
