import { loadPropsWithLocale } from "@logics/utils/i18n";
import GeneralHead from "@logics/tags/GeneralHead";
import ErrorPage from "@domains/common/views/errorContents/ErrorPage";
import AppLayout from "@domains/common/views/layouts/AppLayout/AppLayout";

const Error404Page = () => {
  return (
    <>
      <GeneralHead />

      <AppLayout
        hideFooter
        navbar={{
          title: "",
        }}
      >
        <ErrorPage />
      </AppLayout>
    </>
  );
};

export default Error404Page;

export const getStaticProps = loadPropsWithLocale();
